<template>
  <div class="inputNumber">
    <a @click.prevent="decreaseValue()" class="dec" href=""><span>-</span></a>
    <input type="text" name="inputNumberInput" v-model="numValue" />
    <a @click.prevent="increaseValue()" class="inc" href=""><span>+</span></a>
  </div>
  <!--END inputNumber -->
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "InputNumber",
  props: ["name", "value", "min", "max"],
  computed: {
    ...mapState({
      currentCart: state => state.cart
    }),
    numValue: {
      get () {
        return this.value;
      },
      set (newValue) {
        if (this.max) {
          if (newValue > this.max) {
            newValue = this.max;
            return;
          }
        }
        if (this.min) {
          if (newValue < this.min) {
            newValue = this.min;
            return;
          }
        }
        this.$emit('valueChange', newValue);
      }
    }
  },
  methods: {
    changeHandler: function () {
      this.$emit('valueChange', this.numValue);
    },
    increaseValue: function() {
      if (this.max) {
        if (this.numValue + 1 > this.max) {
          return;
        }
      }
      this.numValue++;
    },
    decreaseValue: function() {
      if (this.min) {
        if (this.numValue - 1 < this.min) {
          return;
        }
      }
      this.numValue--;
    }
  }
};
</script>

<style lang="scss" scoped>
.inputNumber {
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  grid-template-rows: 1fr;
  
  a,
  input {
    height: 24px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.1);
    line-height: 24px;
    color: inherit;
  }
  a.dec,
  a.inc {
    display: block;
    transition: 0.3s;
    span {
      transition: 0.3s;
      display: none;
    }
  }
  a.dec {
    text-align: center;
    border-radius: 5px 0 0 5px;
  }
  a.inc {
    text-align: center;
    border-radius: 0 5px 5px 0;
  }
  input {
    width: 100%;
    min-width: 20px;
    border: none;
    color: inherit;
    text-align: center;
    outline: none;
  }
  &:hover {
    a span {
      display: inline;
    }
    a.dec {
      transform: translateX(-1px);
    }
    a.inc {
      transform: translateX(1px);
    }
  }

}
</style>
