<template>
  <div class="cartItem">
    <div class="itemDetails">
      <div class="itemThumbnail">
        <img :src="itemData.thumbnail" :alt="itemData.name" />
      </div>
      <div class="itemName">
        {{itemData.name}}
        <span v-if="cartMode">
          <br>
          <a class="removeItemTrigger" @click.prevent="removeCartItem()">remove item</a>
        </span>
      </div><!--END itemName-->
    </div><!--END itemDetails-->
    <div class="itemPricing">
      <div class="itemPrice">${{currencyFormat(itemData[this.unitPriceProperty])}}</div>
      <div class="itemQuantity" :class="{ cartMode: cartMode }">
        <div class="timesX">&times;</div> 
        <div v-if="!cartMode">{{itemQuantity}}</div>
        <input-number v-else v-model="itemQuantity" min="1" max="9999" @valueChange="updateQuantity"></input-number>
      </div><!--END itemQuantity-->
      <div class="itemTotal">${{currencyFormat(itemTotal)}}</div>
    </div><!--END itemPricing-->
  </div><!-- END cartItem -->
</template>

<script>
import { mapState } from "vuex";
import InputNumber from "@/components/Inputs/InputNumber";
export default {
  name: "CartItem",
  props: {
    itemData: { type: Object, default: null },
    cartMode: {type: Boolean, default: false},
    unitPriceProperty: {type: String, default: "unit_price"}
  },
  computed: {
    ...mapState({
      currentCart: state => state.cart
    }),
    itemTotal: function () {
       return this.itemData[this.unitPriceProperty] * this.itemData.quantity
    },
    itemQuantity: {
      get () {
        return this.itemData.quantity;
      },
      set (value) {
        var quantityItem = {
          itemId: this.itemData.id,
          newQuantity: value,
        };
        this.$store.dispatch('updateQuantityAction', quantityItem);
      }
    }
  },
  components: {
    InputNumber
  },
  methods: {
    currencyFormat(num) {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    removeCartItem() {
      this.$store.dispatch("removeCartAction", this.itemData);
    },
    updateQuantity(newValue) {
        var quantityItem = {
          itemId: this.itemData.id,
          newQuantity: newValue,
        };
        this.$store.dispatch('updateQuantityAction', quantityItem);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.cartItem {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  padding-bottom: 10px;
  margin: 10px 20px 0;
  justify-content: flex-end;
  align-items: center;
  text-align: left;

  @include for-small-down {
    flex-direction: column;
  }

  .itemDetails {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color:#ffffff;
    position: relative;
    white-space: nowrap;
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items: center;

    @include for-small-down {

    }
    .itemThumbnail {
      padding-right: 10px;
      height: 42px;
      width: 40px;
      display: flex;
      align-items: center;

      img{
        max-width:100%;
      }
    }

    .removeItemTrigger {
      font-weight: normal;
      font-size: 13px;
      padding: 3px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .itemPricing{
    display:flex;
    flex-direction:row;
    @include for-small-down {
      width: 100%;
    }
  }
    
  .itemPrice {
    text-align: right;
    width: 140px;

    @include for-small-down {
      text-align: left;
      width:auto;
    }
  }
  .itemQuantity {
    text-align: right;
    width: 16px;
    white-space: nowrap;
    margin-left: 10px;
    display: flex;
    flex-direction: row;

    @include for-small-down {
      text-align: left;
    }

    &.cartMode {
      width: 80px;
    }

    .timesX {
      width: 20px;
      margin-right: 10px;
    }

  }
  .itemTotal {
    text-align: right;
    width:128px;
    @include for-small-down {
      width:100%;
    }
  }
  
}
  
</style>